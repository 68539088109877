define("web-map/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "axios"], function (_exports, _emberData, _dataAdapterMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Inflector from 'ember-inflector';
  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    // buildURL: function(root, suffix, record) {
    //   console.log('buildURL');
    //   var url = this._super();
    //   console.log(url);
    //   console.log(root);
    //   console.log(suffix);
    //   console.log(record);
    //   if (url.toString().indexOf('/api/v1') === -1) {
    //     alert('NO /api/v1');
    //   }
    //   let modelName = Inflector.inflector.pluralize(root);
    // //   return `${url}/${modelName}`;
    // // },
    // urlForFindHasMany(id, modelName, snapshot) {
    //   console.log('urlForFindHasMany');
    //   let baseUrl = this.buildURL(id, modelName);
    //   console.log(baseUrl);
    //   console.log(id);
    //   console.log(modelName);
    //   console.log(snapshot);
    //   console.log(`${baseUrl}/relationships`);
    //   return `${baseUrl}`;
    // },

    /*
      temporary-fix because sails-ember-rest doesn't provide the namespace in the link
    */
    findHasMany(store, snapshot, link) {
      return this.ajax(this.get('namespace') + link, 'GET');
    },

    /*
      Override the updateRecord(), so can change the default HTTP's Method, from "PUT" to "PATCH"
      https://stackoverflow.com/a/31300911
      https://github.com/emberjs/data/blob/e89732a5aefb6a81b46927da1c6f048f4aede85e/packages/ember-data/lib/adapters/rest-adapter.js#L621
    */
    updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, "PATCH", {
        data: data
      });
    },

    coalesceFindRequests: true,
    namespace: '/api/v1',
    session: Ember.inject.service('session'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let headers = {};

      if (this.session.isAuthenticated) {
        // OAuth 2
        headers['Authorization'] = "Bearer ".concat(this.session.data.authenticated.access_token);
      }

      return headers;
    }),

    authorizeToken(xhr) {
      let {
        Authorization
      } = this.get('headers');

      if (xhr) {
        xhr.setRequestHeader('Authorization', Authorization);
      } else {
        $.ajaxSetup({
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', Authorization);
          }
        });
        _axios.default.defaults.headers.common['Authorization'] = Authorization;
      }
    },

    //this is dependent on production/development environment
    //It is configured in config/environment.js
    //host: ClientENV.hostUrl
    //add IP from $DOCKER_HOST if --docker flag is set

    /*
      change host to your IP Address if you want to make it available on LAN
    */
    hostSurvey: 'https://survey.gov.bn',
    host: 'https://geoportal.survey.gov.bn',
    serverHost: 'https://geoportal.survey.gov.bn',
    mainHost: 'https://geoportal.survey.gov.bn',
    gpserverHost: 'https://geoportal.survey.gov.bn',
    erdasHostAtBackend: 'https://geoportal.survey.gov.bn',
    erdasHost: 'https://geoportal.survey.gov.bn',
    ecomHost: 'https://geoportal.survey.gov.bn',
    ecomWeb: 'https://maps.survey.gov.bn',
    ecomWebsite: 'https://maps.survey.gov.bn',
    recaptchaSiteKey: '6Lck5B0UAAAAALQMYJwzAWLQW7ryfwoZAYXdJsBO',
    recaptchaSecretKey: '6Lck5B0UAAAAAFRiZERSs4t4KXvhZTtbQG7_8HuB',
    inactivityDuration: 20 // in minutes

    /*host: 'http://192.168.1.115:4200',
    serverHost: 'http://192.168.1.115:1337'*/

  });

  _exports.default = _default;
});