define("web-map/components/ol-map-panel", ["exports", "web-map/templates/components/ol-map-panel", "web-map/config/environment"], function (_exports, _olMapPanel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    olmap2svc: Ember.inject.service('ol-mapservice'),
    data_coordinates: null,
    layout: _olMapPanel.default,
    isInitialized: false,
    classNameBindings: ['mapCss'],
    mapCss: Ember.computed('cssClass', function () {
      return _environment.default.W3O_openlayers.ol_map_panel;
    }),

    completeMapSetup() {
      const olmap2svc = this.get('olmap2svc');
      const marker = this.get('marker');
      const map = this.get('map'); //console.log("Inside ol map panel component, completeMapSetup map is: "+map)
      //console.log("Inside ol map panel component, marker is: "+marker)

      map.setTarget(this.element);
      const initialExtent = this.get('initialExtent');
      if (initialExtent) map.getView().fit(initialExtent, map.getSize()); //if (marker) olmap2svc.plotOnePoint(map, marker)
    },

    didInsertElement() {
      //this.get('registerComponent')(this);
      //this.get('finishMapSetup')(this.element);
      if (_environment.default.W3O_openlayers.deployment == 'RAD') {
        const olmap2svc = this.get('olmap2svc');
        const marker = this.get('marker');
        const map = this.get('map'); //const map = olmap2svc.get('map')
        //console.log("Inside ol map panel component, didInsertElement map is: "+map)
        //console.log('Model providing map marker coordinates...')
        //console.log(this.get('positionList'))
        //console.log(marker)

        const markerCent = this.get('markerCenter'); //console.log(markerCent); 

        if (map !== undefined && markerCent !== undefined) {
          //console.log('Zoom to Marker Center');
          let yx = markerCent.map(ele => parseFloat(ele));

          if (isNaN(yx[0]) || isNaN(yx[1])) {
            console.log('NaN doublecheck again...');
          } else {
            let yx1 = yx.slice();
            olmap2svc.plotOnePoint(map, yx);
            olmap2svc.zoomToCenter(yx1);
          }
        }
      }
    },

    observer: Ember.observer('isInitialized', function () {
      if (this.isInitialized) {
        this.get('registerComponent')(this);
        this.get('finishMapSetup')(this.element);

        if (_environment.default.W3O_openlayers.deployment == 'RAD') {
          const olmap2svc = this.get('olmap2svc');
          const marker = this.get('marker');
          const map = this.get('map'); //console.log("Inside ol map panel component, observer isInitialized map is: "+map);
          //console.log('MarkerCenter coordinates...');      

          const markerCent = this.get('markerCenter'); //console.log(markerCent); 

          if (map !== undefined && markerCent !== undefined) {
            //console.log('Zoom to Marker Center');
            let yx = markerCent.map(ele => parseFloat(ele));

            if (isNaN(yx[0]) || isNaN(yx[1])) {
              console.log('NaN doublecheck again...');
            } else {
              let yx1 = yx.slice();
              olmap2svc.plotOnePoint(map, yx);
              olmap2svc.zoomToCenter(yx1);
            }
          }
        }
      }
    }),

    willDestroyElement() {
      this.get('deregisterComponent')(); // this._super(...argument);    //remove because error when click home button
    }

  });

  _exports.default = _default;
});