define("web-map/routes/shopping-cart", ["exports", "axios", "web-map/utils/get-cookie", "ember-simple-auth/mixins/authenticated-route-mixin", "web-map/mixins/w3o-users-roles/check-user-role", "ol/proj"], function (_exports, _axios, _getCookie, _authenticatedRouteMixin, _checkUserRole, _proj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _checkUserRole.default, {
    authObject: Ember.inject.service('session'),

    model() {
      return this.store.query('order', {
        where: {
          user: this.get('authObject.data.user.id'),
          isdelete: false,
          status: 'Process'
        }
      });
    },

    async setupController(controller, model) {
      model = model.objectAt(0);
      controller.set('model', model);
      controller.set('user', this.get('authObject.data.user.email'));

      if (model) {
        this.updateShoppingCart();
        this.generatePreview();
      }

      if ((0, _getCookie.default)('isMobile') === 'Yes') {
        controller.set('imageh', '500');
      } else {
        controller.set('imageh', '768');
      }
    },

    async generatePreview() {
      let self = this;
      let controller = this.get('controller');
      let model = controller.get('model');

      if (model.get('type') === 'RSO' || model.get('type') === 'Geodetic') {
        controller.set('rso_preview_ready', false);
        controller.set('preview_image', '/images/preview-loading.png');
        let export_type = model.get('type') === 'RSO' ? 'rsoexport' : 'geodeticexport';
        let orderDetails = await this.store.query(export_type, {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
        let image_name, preview_name, with_orthophoto, with_height, is_public;
        let rso_ref, rso_type, lot_type, lot_num, book_code;
        let ctl_pnt;
        orderDetails.forEach((order, index) => {
          if (index === 0) {
            if (model.get('type') === 'RSO') {
              rso_ref = order.rso_ref;
              rso_type = order.rso_type;
              image_name = order.image_name;
              preview_name = order.preview_name;
              lot_type = order.lot_type;
              lot_num = order.lot_num;
              book_code = order.book_code;
              with_orthophoto = order.with_orthophoto;
              is_public = order.is_public;
            } else {
              ctl_pnt = order.ctl_pnt;
              image_name = order.image_name;
              preview_name = order.preview_name;
              with_height = order.with_height;
              is_public = order.is_public;
            }
          }
        });
        let end_url = model.get('type') === 'RSO' ? '/rsosheets/generateRSOSheet' : '/geodetics/generateGeodeticSheet';
        let response = null;
        let url_featureexport = "".concat(this.store.adapterFor('application').get('serverHost')).concat(this.store.adapterFor('application').get('namespace')).concat(end_url);
        let generate_param;

        if (model.get('type') === 'RSO') {
          generate_param = {
            rso_ref,
            rso_type,
            lot_type,
            lot_num,
            book_code
          };
        } else {
          generate_param = {
            ctl_pnt
          };
        }

        response = await _axios.default.post(url_featureexport, { ...generate_param,
          with_orthophoto,
          with_height,
          order_number: model.get('order_number'),
          image_name,
          preview_name,
          generate_type: 'pdf',
          erdashost_url: self.store.adapterFor('application').get('erdasHost'),
          is_public
        }).catch(error => {
          console.log("An error occured while generating ".concat(model.get('type'), " Sheet Preview, ").concat(error.response.data));
          alert("An error occured while generating ".concat(model.get('type'), " Sheet Preview, ").concat(error.response.data));
          return null;
        });

        if (response) {
          controller.set('rso_preview_ready', true);
          controller.set('preview_image', "".concat(this.store.adapterFor('application').get('serverHost'), "/RSO_Digital/").concat(model.get('order_number'), "/preview/").concat(preview_name, ".png"));
        }
      }
    },

    async updateShoppingCart() {
      let controller = this.get('controller');
      let model = controller.get('model');
      controller.set('orderType', model.get('type'));
      let totalPrice = 0;
      let totalSize = 0;
      let records;

      if (controller.get('orderType') === 'Feature') {
        controller.set('showPreview', false);
        records = await this.store.query('featureexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (controller.get('orderType') === 'Imagery') {
        controller.set('showPreview', false);
        records = await this.store.query('imageexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (controller.get('orderType') === 'RSO') {
        controller.set('showPreview', true);
        records = await this.store.query('rsoexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (controller.get('orderType') === 'Geodetic') {
        controller.set('showPreview', true);
        records = await this.store.query('geodeticexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        }); //console.log(records);
      }

      if (controller.get('orderType') === 'RSO' || controller.get('orderType') === 'Geodetic') {
        controller.set('show_addmoreitem', false);
        controller.set('show_dataarea', false);
      } else {
        controller.set('show_addmoreitem', true);
        controller.set('show_dataarea', false);
      } // source: https://stackoverflow.com/a/18650828


      const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        let k = 1024;
        let dm = decimals < 0 ? 0 : decimals;
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let i = Math.floor(Math.log(bytes) / Math.log(k)); //console.log('i'); console.log(i);

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      };

      records.forEach(record => {
        if (typeof record.get("price") === 'string') record.set("price", parseFloat(record.get("price")));
        if (typeof record.get("area_size") === 'string') record.set("area_size", parseFloat(record.get("area_size")));
        if (typeof record.get("data_size") === 'string') record.set("data_size", parseFloat(record.get("data_size")));
        totalPrice += record.price;
        totalSize += record.data_size;
        record.set("formatted_data_size", formatBytes(record.data_size));
        record.set("formatted_price", parseFloat(record.get('price').toFixed(2)).toLocaleString());
        record.set("area_size", parseFloat(record.area_size.toFixed(4)).toLocaleString());
        record.set("scala", Math.round(record.scala));
      });
      controller.set('records', records);
      controller.set('totalPrice', parseFloat(totalPrice.toFixed(2)).toLocaleString());
      controller.set('totalSize', formatBytes(totalSize));
      model.set("total_price", parseFloat(totalPrice.toFixed(2)).toLocaleString());
      model.save().then(result => {
        console.log("Order saved"); //console.log(result);
      });
    },

    actions: {
      async deleteSingle(id) {
        let records = this.get('controller').get('records');
        let updatedRecords = records.filter(record => {
          return record.id !== id;
        });
        this.get('controller').set('records', updatedRecords);
        let type;

        if (this.get('controller').get('orderType') === 'Feature') {
          type = 'featureexport';
        } else if (this.get('controller').get('orderType') === 'Imagery') {
          type = 'imageexport';
        }

        let selectedRecord = await this.store.findRecord(type, id);
        selectedRecord.set('isdelete', true);
        selectedRecord.save().then(() => {
          this.updateShoppingCart();
        });
      },

      addMoreItem() {
        //cannot use transitionTo because it wont load the map
        //Go back to /momap if using mobile app
        if ((0, _getCookie.default)('isMobile') === 'Yes') {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/momap"));
        } else {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
        }
      },

      showCancelOrder() {
        $("#cancel-order-modal").modal("show");
      },

      cancelOrder() {
        let controller = this.get('controller');
        let model = controller.get('model');
        model.set('status', 'Cancelled');
        model.save().then(() => {
          $("#cancel-order-modal").modal("hide"); //cannot use transitionTo because it wont load the map
          //Go back to /momap if using mobile app

          if ((0, _getCookie.default)('isMobile') === 'Yes') {
            window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/momap"));
          } else {
            window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
          }
        });
      },

      checkoutAndPay() {
        if (!this.get('authObject.data.user.receive_cash_payment')) {
          this.transitionTo('checkout');
        } else {
          this.transitionTo('cashpayment');
        }
      },

      warningPreview() {
        alert('Please wait while we generating the preview.');
      },

      gotoHome() {
        //cannot use transitionTo because it wont load the map
        //Go back to /momap if using mobile app
        if ((0, _getCookie.default)('isMobile') === 'Yes') {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/momap"));
        } else {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
        }
      }

    }
  });

  _exports.default = _default;
});