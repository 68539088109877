define("web-map/components/map-geodetic", ["exports", "ember-cp-validations", "ember-light-table", "ol/style.js", "ol/format/GeoJSON.js", "ol/layer/Vector.js", "ol/source/Vector.js", "ol/Feature", "ol/geom/Polygon", "ol/geom/Point", "ol/geom/LinearRing"], function (_exports, _emberCpValidations, _emberLightTable, _style, _GeoJSON, _Vector, _Vector2, _Feature, _Polygon, _Point, _LinearRing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import TableCommon from '../mixins/table-common';
  const Validations = (0, _emberCpValidations.buildValidations)({
    gdtsearchvalue: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("unique-gdtsearchvalue", {
      showSuggestions: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    authObject: Ember.inject.service('session'),
    store: Ember.inject.service(),
    columns: Ember.computed("gdtsearchtype", function () {
      let col1 = "";
      let col2 = "";

      switch (this.get("gdtsearchtype.name")) {
        case "Geodetic":
        case "Geodetic_exact":
          col1 = "Station Number";
          col2 = "Details";
          break;

        case "Lots":
        case "Lots_exact":
          col1 = "LOT Num";
          col2 = "RSO";
          break;

        case "TOL":
          col1 = "TOL Num";
          col2 = "RSO";
          break;

        case "Gazette":
        case "Gazette_exact":
          col1 = "Gazette Num";
          col2 = "Owner";
          break;

        default:
          console.log("Error Encountered in Search Case Switch");
      }

      return [{
        label: col1,
        valuePath: "objectnum",
        responsiveHidden: false,
        //must be false
        breakpoints: ["mobile", "tablet", "desktop"] //width: ($(window).width() * 0.23 * 0.3) + 'px'

      }, {
        label: col2,
        valuePath: "objectfield1",
        responsiveHidden: false,
        breakpoints: ["mobile", "tablet", "desktop"] //width: ($(window).width() * 0.23 * 0.7) + 'px'

      }];
    }),
    table: Ember.computed("modelrso", function () {
      return _emberLightTable.default.create({
        columns: this.get("columns"),
        rows: this.get("modelrso")
      });
    }),
    //   rso_searchoption: computed("rsosize", function() {
    //       if(this.rsosize){
    //         let size_index = this.rso_sizeoption.findIndex(item => item.name === this.rsosize.name);
    //         return this.get('rso_sizeoption')[size_index].search_item;
    //       }
    //   }),
    //   rsosearchtype: computed("rsosize", function() {
    //       if (this.rsosize){
    //         let size_index = this.rso_sizeoption.findIndex(item => item.name === this.rsosize.name);
    //         return this.rso_sizeoption[size_index].search_item[0];
    //       }
    //   }),
    gdtsearchtype: {
      name: 'Geodetic',
      label: 'Geodetic Station (Partial Search)'
    },
    gdt_searchoption: [{
      name: 'Geodetic',
      label: 'Geodetic Station (Partial Search)'
    }, {
      name: 'Geodetic_exact',
      label: 'Geodetic Station'
    }, {
      name: 'Lots',
      label: 'Lot (Partial Search)'
    }, {
      name: 'Lots_exact',
      label: 'Lot'
    }, {
      name: 'Gazette',
      label: 'Gazette (Partial Search)'
    }, {
      name: 'Gazette_exact',
      label: 'Gazette'
    }, {
      name: 'TOL',
      label: 'TOL'
    }],
    rsosize: null,
    olMapservice: Ember.inject.service("ol-mapservice"),
    //   rso_sizeoption: [
    //     {
    //         name: 'A1',
    //         label: 'A1',
    //         search_item: [
    //             {
    //                 name: 'RSO',
    //                 label: 'RSO Reference'
    //             }
    //         ]
    //     },
    //     {
    //         name: 'A3',
    //         label: 'A3',
    //         search_item: [
    //             {
    //                 name: "Lots",
    //                 label: "Lot (Partial Search)",
    //             },
    //             {
    //                 name: "Lots_exact",
    //                 label: "Lot",
    //             },
    //             {
    //                 name: "Lots_EDR",
    //                 label: "EDR (Partial Search)",
    //             },
    //             {
    //                 name: "Gazette",
    //                 label: "Gazette (Partial Search)",
    //             },
    //             {
    //                 name: "Gazette_exact",
    //                 label: "Gazette",
    //             },
    //             {
    //                 name: "TOL",
    //                 label: "TOL",
    //             }
    //         ]
    //     }
    //   ],
    gdtsearchvalue: "",
    ffeatures: [],
    modelrso: [],
    usedVectorSource: null,
    usedVectorLayer: null,
    usedMarkerVectorLayer: null,

    /* modelrso: [{
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }, {
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }, {
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }], */
    isResizeCapture: false,
    canClearGeodeticResults: false,
    layersLoaded: false,
    doClearResults: Ember.computed(function () {
      const olMapservice = this.get("olMapservice");
      olMapservice.clearSearchResults(); //console.log("Doing Clear Results");

      return 1;
    }),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {//   if (this.rso_type){
      //     let size_index = this.rso_sizeoption.findIndex(item => item.name === this.rso_type);
      //     this.set("rsosize", this.get('rso_sizeoption')[size_index]);
      //   } else {
      //       this.set('rsosize', this.rso_sizeoption[1]);
      //   }
    },

    observer: Ember.observer("canClearGeodeticResults", function () {
      //console.log("trying to clear geodetic search results after srs change");
      if (this.canClearGeodeticResults) {
        //console.log("can clear geodetic search results after srs change");
        this.set("modelrso", []); //this.set('canClearRSOResults', false);
        //   this.resetClearRSOResults();
      } //console.log(this.get("canClearGeodeticResults"));

    }),
    actions: {
      onRowClick(row) {
        const olMapservice = this.get("olMapservice");
        let vs1 = olMapservice.get("featureVectorSource");
        let vla1 = olMapservice.get("featureVectorLayer");
        let map = olMapservice.get("map"); //console.log("onRowClick");
        //console.log(row);
        //console.log(row.get("content"));
        //console.log(row.get("objectnum"));
        //console.log(row.get('geom'));

        let pol1 = row.get("geom");

        let _c_srs = map.getView().getProjection().getCode(); //   let posStr = pol1.Point._content_.pos;
        //   console.log(posStr);
        //   let lrr2 = posStr.trim().split(" ");
        //   console.log(lrr2);
        //   //olMapservice.plotOnePoint(map, lrr2);
        //   map.getView().setCenter(lrr2);
        //   if (_c_srs == "EPSG:4326") {
        //     map.getView().setResolution(0.000005948652404239215);
        //   } else {
        //     map.getView().setResolution(0.6614596562526458386250105833545);
        //   }
        //   //Automatic close the left panel if user is in mobile mode
        //   if (this.get('isMobile') === 'Yes'){
        //     this.toggleFunctionPanel();
        //   }


        if (row.get("objectfield2") == "Place") {
          let posStr = pol1.Point._content_.pos; //console.log(posStr);

          let lrr2 = posStr.trim().split(" "); //console.log(lrr2);
          //olMapservice.plotOnePoint(map, lrr2);

          let lrarr1 = lrr2.map(pos => {
            let coordfloat = parseFloat(pos);
            return coordfloat;
          });
          map.getView().setCenter(lrarr1);

          if (_c_srs == "EPSG:4326") {
            map.getView().setResolution(0.000005948652404239215);
          } else {
            map.getView().setResolution(0.6614596562526458386250105833545);
          }
        } else {
          //console.log("Navigating the Surface object");
          // check for multipolygons eg: mukim search sengkurong
          // pol1 is geom 
          let lrr1 = null;

          if (pol1.hasOwnProperty('MultiSurface')) {
            let tmarr1 = pol1.MultiSurface._content_.surfaceMember;
            lrr1 = tmarr1[0].Surface._content_.patches.PolygonPatch.exterior.LinearRing;
          } else {
            lrr1 = pol1.Surface._content_.patches.PolygonPatch.exterior.LinearRing;
          } //console.log(lrr1);


          let poss1 = lrr1._content_.pos; //console.log(poss1);

          let lrarr1 = poss1.map(function (pos) {
            let posarr = pos.split(" "); //console.log(posarr);

            let posarrfloat = posarr.map(function (coord) {
              let coordfloat = parseFloat(coord);
              return coordfloat;
            }); //console.log(posarrfloat);
            // damn , got to be very careful in how to process the arrays and arrays structure

            let val1 = posarrfloat;
            return val1;
          }); //console.log(lrarr1);
          //lrarr1.pop();

          let lrrobj1 = new _LinearRing.default(lrarr1); //console.log(lrrobj1);

          let poly1 = new _Polygon.default([lrarr1]); //console.log(poly1);
          //poly1.appendLinearRing(lrrobj1);

          const targetFeature = new _Feature.default({
            geometry: poly1
          });
          targetFeature.setStyle(new _style.Style({
            stroke: new _style.Stroke({
              color: "#0000ff",
              width: 3
            }),
            fill: new _style.Fill({
              color: "rgba(0, 0, 255, 0.2)"
            })
          }));
          var geojsonObject = {
            type: "FeatureCollection",
            crs: {
              type: "name",
              properties: {
                name: "EPSG:29873"
              }
            },
            features: [{
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [lrarr1]
              }
            }]
          }; //console.log(geojsonObject);
          //'coordinates': [[[-5e6, -1e6], [-4e6, 1e6], [-3e6, -1e6]]]
          //console.log('polygon linear ring count: ' + pol1.getLinearRingCount());
          //console.log(pol1.getLinearRing(0));
          //console.log(pol1.getLinearRing(0).getType());

          let feGeoJson = new _GeoJSON.default({
            dataProjection: "EPSG:29873",
            featureProjection: "EPSG:29873"
          }); //console.log(feGeoJson);
          //console.log(feGeoJson.readProjection(geojsonObject));

          if (Boolean(this.get("usedVectorSource"))) {
            let uvs = this.get("usedVectorSource");
            uvs.clear();
            this.set("usedVectorSource", null);
          }

          let feVectorSource = new _Vector2.default({
            features: feGeoJson.readFeatures(geojsonObject),
            useSpatialIndex: false
          }); //console.log(feVectorSource);

          this.set("usedVectorSource", feVectorSource); //feVectorSource.addFeature(targetFeature);
          //vla1.setSource(vs1);
          //console.log(vla1.getStyle());

          if (Boolean(this.get("usedVectorLayer"))) {
            map.removeLayer(this.get("usedVectorLayer"));
          }

          var layer22 = new _Vector.default({
            title: "SearchLayer",
            source: feVectorSource,
            zIndex: 200,
            style: new _style.Style({
              stroke: new _style.Stroke({
                color: "red",
                width: 3
              }),
              fill: new _style.Fill({
                color: "rgba(0, 0, 255, 0.2)"
              })
            })
          });
          map.addLayer(layer22);
          this.set("usedVectorLayer", layer22);
          feVectorSource.refresh();
          let polycoord1 = poly1.getFirstCoordinate(); //console.log("testing..........");
          //console.log(polycoord1);

          map.getView().setCenter(polycoord1);

          if (_c_srs == "EPSG:4326") {
            map.getView().setResolution(0.000005948652404239215);
          } else {
            map.getView().setResolution(0.6614596562526458386250105833545); // if (this.get('rsosize').name === 'A3') {
            //     map.getView().setResolution(0.6614596562526458386250105833545);
            // } else {
            //     map.getView().setResolution(1.4614596562526458386250105833545);
            // }
          }
        } //Automatic close the left panel if user is in mobile mode


        if (this.get('isMobile') === 'Yes') {
          this.toggleFunctionPanel();
        }
      },

      // setRSOSize(value) {
      //     this.set("modelrso", []);
      //     let size_index = this.rso_sizeoption.findIndex(item => item.name === value.name);
      //     this.set("rsosize", this.get('rso_sizeoption')[size_index]);
      //     this.set('rsosearchtype', this.rso_sizeoption[size_index].search_item[0]);
      //     console.log("map search type : " + value);
      //     /* window.addEventListener("resize", function () {
      //       console.log('Window Resize Triggered via addEventListener');
      //     }); */ // captured by jquery above
      // },
      setSearchType(value) {
        this.set("modelrso", []);
        this.set("gdtsearchtype", value); //console.log("map search type : ");
        //console.log(value);
      },

      async doSearch() {
        //   if (!this.get('authObject').get('isAuthenticated')) {
        //     this.set('checkUserUrl', `${this.store.adapterFor('application').get('serverHost')}/api/v1/auths/checkUser`)
        //     $("#rso-login").appendTo('body').modal({backdrop: 'static'});
        //     return;
        //   } 
        //this.set('canClearResults', true);
        const olMapservice = this.get("olMapservice");
        let map = olMapservice.get("map");

        let _c_srs = map.getView().getProjection().getCode();

        let srs_split = _c_srs.split(":");

        if (this.layersLoaded === false) {
          this.loadAppLayers(srs_split[1], 'geodetic'); // TODO change hardcode to param
        }

        this.set('layersLoaded', true);
        let self = this;
        this.set("modelrso", []);
        let sv1 = this.get("gdtsearchvalue");
        let st1 = this.get("gdtsearchtype");
        let wfs1 = "";
        let layer1 = "";
        let attr1 = "";
        let str1 = ""; //console.log("map search value : " + sv1 + " search type " + st1.name);

        switch (st1.name) {
          case "Geodetic":
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor("application").get("erdasHost"), "/erdas-apollo/vector/POINTS_GDBD");
            layer1 = "TBL_PT_CTL_TRANS_SHAPE_GDBD";
            attr1 = "VC_STN_NAME_PK";
            str1 = "%" + sv1 + "%";
            break;

          case "Geodetic_exact":
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor("application").get("erdasHost"), "/erdas-apollo/vector/POINTS_GDBD");
            layer1 = "TBL_PT_CTL_TRANS_SHAPE_GDBD";
            attr1 = "VC_STN_NAME_PK";
            str1 = sv1;
            break;

          case "Lots":
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor("application").get("erdasHost"), "/erdas-apollo/vector/LOTS3");
            layer1 = "MV_LOTS3";
            attr1 = "VC_LOTNUM";
            str1 = "%" + sv1 + "%";
            break;

          case "Lots_exact":
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor("application").get("erdasHost"), "/erdas-apollo/vector/LOTS3");
            layer1 = "MV_LOTS3";
            attr1 = "VC_LOTNUM";
            str1 = sv1;
            break;

          case "TOL":
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor("application").get("erdasHost"), "/erdas-apollo/vector/GDBD");
            layer1 = "TOL";
            attr1 = "TOLNUM";
            str1 = "%" + sv1 + "%";
            break;

          case "Gazette":
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor("application").get("erdasHost"), "/erdas-apollo/vector/GAZETTE3");
            layer1 = "MV_GAZETTE1";
            attr1 = "VC_GR_NUM";
            str1 = "%" + sv1 + "%";
            break;

          case "Gazette_exact":
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor("application").get("erdasHost"), "/erdas-apollo/vector/GAZETTE3");
            layer1 = "MV_GAZETTE1";
            attr1 = "VC_GR_NUM";
            str1 = sv1;
            break;

          default:
            console.log("Error Encountered in Search Case Switch");
        }

        let markerVectorLayer = olMapservice.get("vectorLayer"); //   olMapservice.setRSOSize(this.get("rsosize").name);

        let ff1 = await olMapservice.findWfsObjects(olMapservice, wfs1, layer1, attr1, str1, st1.name); //console.log("map-search component found ...");
        //console.log(ff1);

        this.set("modelrso", ff1);
        let redf1 = ff1.map(function (fea1) {
          let mt1 = {
            objectnum: fea1.objectnum
          };
          return mt1;
        });
        this.set("ffeatures", redf1);

        if (this.isResizeCapture == false) {
          (function (self) {
            $(window).bind("resize", function () {
              //console.log("Window Resize Triggered");
              //console.log(value);
              //console.log(self.get("modelrso"));
              let wid1 = $(window).width(); //console.log(wid1);

              if (420 < wid1 && wid1 < 1025) {
                //console.log("Trying to resize 1");
                // self.get("table").columns.objectAt(0).set("width", wid1 * 0.3 * 0.3 + "px");
                // self.get("table").columns.objectAt(1).set("width", wid1 * 0.3 * 0.6 + "px");
                self.get("table").columns.objectAt(0).set("width", "30%");
                self.get("table").columns.objectAt(1).set("width", "70%"); //self.get('table').columns.objectAt(1).set('width', '0px');
                //self.get('table').columns.objectAt(1).set('hidden', true);
                //self.get('table').set('rows', self.get('ffeatures'));
              } else if (wid1 < 420) {
                //console.log("Trying to resize 3");
                self.get("table").columns.objectAt(0).set("width", "40%");
                self.get("table").columns.objectAt(1).set("width", "60%");
                self.get("table").columns.objectAt(1).set("hidden", false);
              } else {
                //console.log("Trying to resize 2");
                // self.get("table").columns.objectAt(0).set("width", wid1 * 0.23 * 0.3 + "px");
                // self.get("table").columns.objectAt(1).set("width", wid1 * 0.23 * 0.6 + "px");
                self.get("table").columns.objectAt(0).set("width", "20%");
                self.get("table").columns.objectAt(1).set("width", "80%");
                self.get("table").columns.objectAt(1).set("hidden", false);
              }
            }).trigger("resize");
          })(this); // this.set("isResizeCapture", true);

        } //this.updateModel(ff1);
        // rerender component
        //this.rerender();

      },

      refreshPage() {
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map?rso_type=").concat(this.get("rsosize").name));
      },

      authorize() {
        this.authorize();
      }

    }
  });

  _exports.default = _default;
});