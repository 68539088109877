define("web-map/components/map-feature-export", ["exports", "ember-cp-validations", "ember-light-table", "ol/style.js", "ol/format/GeoJSON.js", "ol/layer/Vector.js", "ol/source/Vector.js", "ol/Feature", "ol/geom/Polygon", "ol/geom/Point", "ol/geom/LinearRing", "ol/interaction/Draw", "ol/proj.js", "axios"], function (_exports, _emberCpValidations, _emberLightTable, _style, _GeoJSON, _Vector, _Vector2, _Feature, _Polygon, _Point, _LinearRing, _Draw, proj, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import TableCommon from '../mixins/table-common';
  const Validations = (0, _emberCpValidations.buildValidations)({
    searchvalue: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('unique-searchvalue', {
      showSuggestions: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    authObject: Ember.inject.service('session'),
    store: Ember.inject.service(),
    columns: Ember.computed('searchtype', function () {
      let col1 = '';
      let col2 = '';

      switch (this.get('searchtype.name')) {
        case 'Lots':
          col1 = 'LOT Num';
          col2 = 'RSO';
          break;

        case 'TOL':
          col1 = 'TOL Num';
          col2 = 'RSO';
          break;

        case 'Gazette':
          col1 = 'Gazette Num';
          col2 = 'Owner';
          break;
        //   case 'Place':
        //     col1 = '';
        //     col2 = 'Name';
        //     break;

        case 'Kampung':
          col1 = 'Kg Name';
          col2 = 'Mukim';
          break;

        case 'GeoCoordinates':
          col1 = '';
          col2 = '';
          break;

        default:
          console.log('Error Encountered in Search Case Switch');
      }

      ;
      return [{
        label: col1,
        valuePath: 'objectnum',
        responsiveHidden: false,
        //must be false
        breakpoints: ['mobile', 'tablet', 'desktop'] //width: ($(window).width() * 0.23 * 0.3) + 'px'

      }, {
        label: col2,
        valuePath: 'objectfield1',
        responsiveHidden: false,
        breakpoints: ['mobile', 'tablet', 'desktop'] //width: ($(window).width() * 0.23 * 0.7) + 'px'

      }];
    }),
    table: Ember.computed('model1', function () {
      return _emberLightTable.default.create({
        columns: this.get('columns'),
        rows: this.get('model1')
      });
    }),
    olMapservice: Ember.inject.service('ol-mapservice'),
    // stoptions: ['Lots', 'TOL', 'Gazette', 'Place', 'Kampung'],
    stoptions: [{
      name: 'Lots',
      label: 'Lot'
    }, {
      name: 'TOL',
      label: 'TOL'
    }, {
      name: 'Gazette',
      label: 'Gazette'
    }, // {
    //   name: 'Place',
    //   label: 'Place'
    // },
    {
      name: 'Kampung',
      label: 'Kampung'
    }, {
      name: 'None',
      label: 'None'
    }],
    btoptions: [{
      name: 'Polygon',
      label: 'Polygon'
    }, // {
    //   name: 'Circle',
    //   label: 'Circle'
    // },
    {
      name: 'None',
      label: 'None'
    }],
    epoptions: [{
      name: 'GML',
      label: 'GML file'
    }, {
      name: 'Shapefile',
      label: 'Shapefile'
    }, {
      name: 'None',
      label: 'None'
    }],
    // searchtype: 'Lots',
    searchtype: {
      name: 'None',
      label: 'None'
    },
    searchvalue: '',
    boxtype: {
      name: 'None',
      label: 'None'
    },
    boxvalue: '',
    exporttype: {
      name: 'None',
      label: 'None'
    },
    exportvalue: '',
    drawToolObject: null,
    ffeatures: [],
    model1: [],
    drawVectorSource: null,
    usedVectorSource: null,
    usedVectorLayer: null,
    usedMarkerVectorLayer: null,

    /* model1: [{
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }, {
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }, {
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }], */
    isResizeCapture: false,
    canClearResults: false,
    doClearResults: Ember.computed(function () {
      const olMapservice = this.get('olMapservice');
      olMapservice.clearSearchResults(); //console.log('Doing Clear Results');

      return 1;
    }),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {},

    observer: Ember.observer('canClearResults', function () {
      //console.log('trying to clear search results after srs change');
      if (this.canClearResults) {
        //console.log('can clear search results after srs change');
        this.set('model1', []); //this.set('canClearResults', false);
        //   this.resetClearResults();
      } //console.log(this.get('canClearResults'));

    }),

    async doFeatureExport(layer, boxpoly, boxgeom, srs, user, exportType) {
      const map = this.get('olMapservice').get('map');
      let wfs1 = '';
      let layer1 = '';
      let scoord1 = '';
      const dpi = 96;
      const inchesPerMetre = 39.37;
      let unit = map.getView().getProjection().getUnits();
      let resolution = map.getView().getResolution();
      let currentScale = resolution * proj.METERS_PER_UNIT[unit] * inchesPerMetre * dpi; //let boxArea = Math.floor(Math.random() * 100); // to be replace with a function that calculate area

      let boxArea = boxpoly.getArea(); // should be sqm

      let self = this;

      switch (layer) {
        case 'Lots':
          //swfs1,slayer1,ssrs1,sattr1,sstr1
          wfs1 = "".concat(self.store.adapterFor('application').get('erdasHostAtBackend'), "/erdas-apollo/vector/LOTS3");
          layer1 = 'MV_LOTS3';
          break;

        case 'TOL':
          //swfs1,slayer1,ssrs1,sattr1,sstr1
          wfs1 = "".concat(self.store.adapterFor('application').get('erdasHostAtBackend'), "/erdas-apollo/vector/GDBD");
          layer1 = 'TOL';
          break;

        case 'Gazette':
          //swfs1,slayer1,ssrs1,sattr1,sstr1
          wfs1 = "".concat(self.store.adapterFor('application').get('erdasHostAtBackend'), "/erdas-apollo/vector/GAZETTE3");
          layer1 = 'MV_GAZETTE1';
          break;

        case 'Kampung':
          //swfs1,slayer1,ssrs1,sattr1,sstr1
          wfs1 = "".concat(self.store.adapterFor('application').get('erdasHostAtBackend'), "/erdas-apollo/vector/GDBD_BR10");
          layer1 = 'ADMINBNDY_P_AGREEMENT';
          break;

        default:
          console.log('Error Encountered in Fetch Feature Case Switch');
      }
      /** <ogc:Filter xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns="http://www.opengis.net/ogc">
       * <ogc:Within><ogc:PropertyName xmlns:prp="http://www.erdas.com/wfs">SHAPE</ogc:PropertyName><gml:Polygon xmlns:gml="http://www.opengis.net/gml">
       * <gml:outerBoundaryIs><gml:LinearRing><gml:coordinates>581697,546458 583916,546217 583783,545265 581526,545174 581697,546458</gml:coordinates>
       * </gml:LinearRing></gml:outerBoundaryIs></gml:Polygon></ogc:Within></ogc:Filter>
       */

      /**
       * http://survey.gov.bn/erdas-apollo/vector/LOTS3?SERVICE=WFS&VERSION=1.1.0&REQUEST=GetFeature&SRS=EPSG%3A5247&typename=MV_LOTS3&Filter=
       * %3Cogc%3AFilter%20xmlns%3Agml%3D%22http%3A%2F%2Fwww.opengis.net%2Fgml%22%20xmlns%3Aogc%3D%22http%3A%2F%2Fwww.opengis.net%2Fogc%22%20xmlns%3D%22http%3A%2F%2Fwww.opengis.net%2Fogc%22
       * %3E%3Cogc%3AWithin%3E%3Cogc%3APropertyName%20xmlns%3Aprp%3D%22http%3A%2F%2Fwww.erdas.com%2Fwfs%22%3ESHAPE%3C%2Fogc%3APropertyName%3E%3Cgml%3APolygon%20xmlns%3Agml%3D%22http%3A%2F%2Fwww.opengis.net%2Fgml%22
       * %3E%3Cgml%3AouterBoundaryIs%3E%3Cgml%3ALinearRing%3E%3Cgml%3Acoordinates%3E581697%2C546458%20583916%2C546217%20583783%2C545265%20581526%2C545174%20581697%2C546458%3C%2Fgml%3Acoordinates%3E%3C%2Fgml%3ALinearRing%3E%3C%2Fgml%3AouterBoundaryIs
       * %3E%3C%2Fgml%3APolygon%3E%3C%2Fogc%3AWithin%3E%3C%2Fogc%3AFilter%3E
       */


      let ogcxml1a = '<ogc:Filter xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns="http://www.opengis.net/ogc"><ogc:Within><ogc:PropertyName xmlns:prp="http://www.erdas.com/wfs">SHAPE</ogc:PropertyName>';
      let ogcxml2a = '<gml:Polygon xmlns:gml="http://www.opengis.net/gml"><gml:outerBoundaryIs><gml:LinearRing><gml:coordinates>';
      let ogcxml3 = ''; // where polygon coordinates fit in

      let ogcxml2b = '</gml:coordinates></gml:LinearRing></gml:outerBoundaryIs></gml:Polygon>';
      let ogcxml1b = '</ogc:Within></ogc:Filter>'; //boxgeom is an array of polygon coordinates

      let tempstr = '';

      for (let i = 0; i < boxgeom.length; i++) {
        if (i % 2 == 0) {
          if (tempstr === '') tempstr = tempstr + boxgeom[i];else tempstr = tempstr + ' ' + boxgeom[i];
        } else {
          tempstr = tempstr + ',' + boxgeom[i];
        } //console.log(boxgeom[i]);

      }

      ogcxml3 = tempstr; //console.log(ogcxml3);

      let completexml = ogcxml1a + ogcxml2a + ogcxml3 + ogcxml2b + ogcxml1b; //console.log('ol-mapservice doFeatureExport completexml : ' + completexml);

      /*** <gml:Point gml:id="p21" srsName="http://www.opengis.net/def/crs/EPSG/0/4326">
        <gml:pos srsDimension="2">45.67 88.56</gml:pos>
      </gml:Point>  ***/
      //submit feature export to backend

      let response = null;
      let url_featureexport = this.store.adapterFor('application').get('serverHost') + this.store.adapterFor('application').get('namespace') + '/feature/doFeatureExport';
      let area_size = (boxArea / 1000000).toFixed(3);
      response = await _axios.default.post(url_featureexport, {
        "wfs": wfs1,
        "layer": layer1,
        "fetchxml": completexml,
        "srs": srs,
        "scala": Math.round(currentScale),
        "area_size": area_size,
        "user": user,
        "exportType": exportType
      }).catch(error => {
        console.log("An error occured while exporting features, ".concat(error.response.data));
        alert("An error occured while exporting features, ".concat(error.response.data));
        return null;
      });
      return response;
    },

    async fetchBoxWfsObjects(swfs1, slayer1, scoord1, stype) {},

    actions: {
      setDrawBoxType(value) {
        const olMapservice = this.get('olMapservice');
        let map = olMapservice.get('map');
        this.set('boxtype', value); //console.log('map box type : ' + value);

        if (value.name === 'None') {
          //console.log('Going to clear boxshape as choosing None type...');
          let source1 = this.get('drawVectorSource');
          if (source1 !== null) source1.clear();
        }

        let source = new _Vector2.default({
          wrapX: false
        });
        this.set('drawVectorSource', source);
        let vector = new _Vector.default({
          source: source,
          title: 'Features Selection',
          visible: true,
          zIndex: 100,
          style: new _style.Style({
            stroke: new _style.Stroke({
              color: '#0000ff',
              width: 2
            }),
            fill: new _style.Fill({
              color: 'rgba(0, 0, 255, 0.2)'
            })
          })
        });
        map.addLayer(vector);
        let boxvalue = value.name;
        this.set('boxvalue', boxvalue); //console.log('map box type : ' + boxvalue);
        //console.log(source);
        //console.log(vector);
        //console.log(map.getLayers());
        //let typeSelect = document.getElementById('boxtypeselect');

        if (boxvalue !== 'None') {
          let draw = new _Draw.default({
            source: source,
            type: boxvalue
          });
          map.addInteraction(draw);
          this.set('drawToolObject', draw);
          draw.on('drawend', function (event) {
            let feature = event.feature; //console.log('Draw Polygon, END, feature is..');
            //console.log(feature);

            if (feature !== null && feature !== undefined) {
              map.removeInteraction(draw); //console.log('Already Removed Draw interaction...');
            }
          });
        }

        source.refresh(); //Automatic close the left panel if user is in mobile mode

        if (value.name === 'Polygon' && this.get('isMobile') === 'Yes') {
          this.toggleFunctionPanel();
        }
      },

      exitDrawBox() {
        const olMapservice = this.get('olMapservice');
        let map = olMapservice.get('map'); //console.log(map.getLayers());

        map.removeInteraction(this.get('drawToolObject'));
        let reset1 = {
          name: 'None',
          label: 'None'
        };
        this.set('boxtype', reset1);
      },

      clearBoxShape() {
        const olMapservice = this.get('olMapservice');
        let map = olMapservice.get('map');
        let source1 = this.get('drawVectorSource');
        source1.clear();
      },

      setLayerType(value) {
        this.set('searchtype', value); //console.log('map feature type : ' + value);

        let searchvalue = value.name;
        this.set('searchvalue', searchvalue);
      },

      setFeatureExportType(value) {
        this.set('exporttype', value); //console.log('feature export type : ' + value);

        let exportvalue = value.name;
        this.set('exportvalue', exportvalue);
      },

      async doFeatureExport(user) {
        if (!this.get('searchvalue')) {
          alert('Please fill the Export Feature Type');
          return;
        }

        if (!this.get('drawVectorSource')) {
          alert('Please fill the Feature Selection Type');
          return;
        }

        if (user === undefined || user === null) {
          user = this.get("authObject.data.user.id");
        }

        const olMapservice = this.get('olMapservice');
        let map = olMapservice.get('map');
        let s_srs = map.getView().getProjection().getCode();
        let layer1 = this.get('searchvalue');
        let box1 = this.get('boxvalue');
        let export1 = this.get('exportvalue');
        let source1 = this.get('drawVectorSource');
        let featureArray = source1.getFeatures();
        let feature0 = featureArray[0];

        if (!feature0) {
          alert('Please draw an area first');
          return;
        }

        let box_poly = feature0.getGeometry();
        let boxArea = box_poly.getArea();
        let area_size = (boxArea / 1000000).toFixed(3);

        if (area_size >= 4) {
          alert('Sorry, the polygon area that you draw is too big, please draw a smaller area');
          return;
        }

        let exportType = this.get('exporttype');

        try {
          var geom1 = feature0.getGeometry().flatCoordinates;
        } catch (err) {
          alert('Export failed. Please make sure you have selected the correct area on the map.');
          return;
        } //let epsgcode = srs.substring(5);    
        //   if (!this.get('authObject').get('isAuthenticated')) {
        //     this.set('checkUserUrl', `${this.store.adapterFor('application').get('serverHost')}/api/v1/auths/checkUser`)
        //     $("#feature-login").appendTo('body').modal({backdrop: 'static'});
        //     return;
        //   }
        //   $("#feature-export").attr("disabled", true);


        if (!this.get('authObject').get('isAuthenticated')) {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/login"));
          return;
        } //console.log('doFeatureExport source1 export1 layer1...');
        //console.log(source1);
        //console.log(featureArray);
        //console.log(geom1);
        //console.log(export1);
        //console.log(layer1);


        let response = await this.doFeatureExport(layer1, box_poly, geom1, s_srs, user, exportType); //layer, boxgeom, srs
        //redirect to shopping-cart

        if (response) {
          $(".login-modal").modal("hide");
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/shopping-cart"));
        } else if (window.location.href === "".concat(this.store.adapterFor('application').get('host'), "/start")) {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/stmap"));
        } else {
          $("#feature-export").attr("disabled", false);
        }
      },

      async extractFeatureInLayer(st1) {
        const olMapservice = this.get('olMapservice');
        wfs1 = st1.wms_url;
        layer1 = st1.target_layer;
        scoord1 = tcoordinate; //console.log({wfs1, layer1, scoord1});

        let fetchRes1 = await this.fetchBoxWfsObjects(wfs1, layer1, scoord1, st1);
        /* try {
          fetchRes1 = await self.fetchNearbyWfsObjects(wfs1, layer1, scoord1, st1);
        } catch(err) {
          console.error(err); 
        } */
        //console.log('Fetch results from fetchBoxWfsObjects');
        //console.log(fetchRes1);

        return fetchRes1;
      },

      authorize() {
        this.authorize();
      }

    }
  });

  _exports.default = _default;
});