define("web-map/components/ol-layer-tree-group", ["exports", "web-map/templates/components/ol-layer-tree-group"], function (_exports, _olLayerTreeGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _olLayerTreeGroup.default,
    tagName: 'div',
    canInitLayers: false,
    canPropagateInitLayers: Ember.computed('canInitLayers', function () {
      return this.get('canInitLayers');
    }),
    isInitLayerGroup: '',
    setup: Ember.on('init', function () {
      /* const layers = this.get('layers')
      console.log("layers in ol-layer-tree-group component: "+layers)
      const exclusiveLayers = layers.filter(layer => layer.get('exclusive'))
      console.log("exclusive layer: "+exclusiveLayers)
      const onExlusiveLayerVisibilityChange = layer => {
        const visible = layer.get('visible')
        if (visible) {
          exclusiveLayers.filter(e => layer !== e)
            .forEach(e => e.set('visible', false))
        }
      }
      console.log("Inside ol layer tree group component")
      exclusiveLayers.forEach(layer => layer.addObserver('visible', onExlusiveLayerVisibilityChange)) */
    }),

    initLayers() {
      /* const layers = this.get('layers')
      console.log("layers in ol-layer-tree-group component: "+layers)
      const exclusiveLayers = layers.filter(layer => layer.get('exclusive'))
      console.log("exclusive layer: "+exclusiveLayers)
      const onExlusiveLayerVisibilityChange = layer => {
        const visible = layer.get('visible')
        if (visible) {
          exclusiveLayers.filter(e => layer !== e)
            .forEach(e => e.set('visible', false))
        }
      }
      console.log("Inside ol layer tree group component")
      exclusiveLayers.forEach(layer => layer.addObserver('visible', onExlusiveLayerVisibilityChange)) */
    },

    observer: Ember.observer('canInitLayers', function () {
      if (this.canInitLayers) {
        const layers = this.get('layers'); //console.log("Inside ol-layer-tree-group component layers (obsrv canInitLayers) : "+layers)
        //console.log(layers)

        const exclusiveLayers = layers.filter(layer => layer.get('exclusive')); //console.log("exclusive layer: "+exclusiveLayers)

        const onExlusiveLayerVisibilityChange = layer => {
          const visible = layer.get('visible');

          if (visible) {
            exclusiveLayers.filter(e => layer !== e).forEach(e => e.set('visible', false));
          }
        }; //console.log("Inside ol layer tree group component before iterate exclusiveLayers...")


        exclusiveLayers.forEach(layer => layer.addObserver('visible', onExlusiveLayerVisibilityChange));
      }
    }),
    observer: Ember.observer('layers', function () {
      //console.log('Inside ol-layer-tree-group component layers (obsrv layers) isInitLayerGroup: ' +this.get('isInitLayerGroup'));
      if (this.canInitLayers) {
        const layers = this.get('layers'); //console.log("Inside ol-layer-tree-group component layers (obsrv layers): ")
        //console.log(layers)
        //need to fix here exclusive layer

        let subExclusiveLayers = null;
        const exclusiveLayers = layers.filter(layer => {
          if ('group_array' in layer) {
            // do not process exclusive for layer group, but must process exclusive for layers in the group
            let layers22 = layer.group_array; //console.log(layers22)

            const exclusiveInnerLayers = layers22.filter(layer => layer.get('exclusive')); //console.log('exclusiveInnerLayers')
            //console.log(exclusiveInnerLayers)

            subExclusiveLayers = exclusiveInnerLayers;
            return layer.get('exclusive');
          } else {
            return layer.get('exclusive');
          }
        }); //console.log("exclusive layers are: ")
        //console.log(exclusiveLayers)
        //console.log(subExclusiveLayers)

        let theExclusiveLayers = exclusiveLayers.concat(subExclusiveLayers);

        const onExclusiveLayerVisibilityChange = layer => {
          //console.log("Inside ol layer tree group component handler for onExclusiveLayerVisibilityChange ...")
          const visible = layer.get('visible'); //console.log(layer)

          if (visible) {
            theExclusiveLayers.filter(e => layer !== e).forEach(e => e.set('visible', false));
          }
        }; //console.log("Inside ol layer tree group component before iterate exclusiveLayers...")


        theExclusiveLayers.forEach(layer => layer.addObserver('visible', onExclusiveLayerVisibilityChange));
      }
    })
  });

  _exports.default = _default;
});