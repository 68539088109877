define("web-map/validators/unique-gdtsearchvalue", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UniqueRSOSearchvalue = _base.default.extend({
    validate(value, options, model, attribute) {
      //console.log('Inside unique-gdtsearchvalue validator...');
      //console.log(attribute);
      //console.log(value);
      //console.log(model);
      //console.log(options);
      let message = ''; //console.log(model.gdtsearchtype);

      switch (model.gdtsearchtype.name) {
        case 'Geodetic':
          message = 'Note: Input the name of the Geodetic Station, can be partial name';
          break;

        default:
          message = '';
      }

      ;
      if (message == '') return true;else return message;
    }

  });

  UniqueRSOSearchvalue.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor(attribute, options) {
      return ['model.gdtsearchtype'];
    }

  });
  var _default = UniqueRSOSearchvalue;
  _exports.default = _default;
});