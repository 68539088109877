define("web-map/routes/payment-success", ["exports", "jquery", "axios", "web-map/utils/get-cookie", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (_exports, _jquery, _axios, _getCookie, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    queryParameters: null,
    hasValidHash: false,
    continueProcessing: true,
    allOrders: null,
    responseDescription: function (vResponseCode) {
      let result = ""; // check if a single digit response code

      if (vResponseCode.length === 1) {
        switch (vResponseCode) {
          case '0':
            result = "Transaction Successful";
            break;

          case '1':
            result = "Transaction Declined";
            break;

          case '2':
            result = "Bank Declined Transaction";
            break;

          case '3':
            result = "No Reply from Bank";
            break;

          case '4':
            result = "Expired Card";
            break;

          case '5':
            result = "Insufficient Funds";
            break;

          case '6':
            result = "Error Communicating with Bank";
            break;

          case '7':
            result = "Payment Server detected an error";
            break;

          case '8':
            result = "Transaction Type Not Supported";
            break;

          case '9':
            result = "Bank declined transaction (Do not contact Bank)";
            break;

          case 'A':
            result = "Transaction Aborted";
            break;

          case 'B':
            result = "Transaction Declined - Contact the Bank";
            break;

          case 'C':
            result = "Transaction Cancelled";
            break;

          case 'D':
            result = "Deferred transaction has been received and is awaiting processing";
            break;

          case 'E':
            result = "Transaction Declined - Refer to card issuer";
            break;

          case 'F':
            result = "3-D Secure Authentication failed";
            break;

          case 'I':
            result = "Card Security Code verification failed";
            break;

          case 'L':
            result = "Shopping Transaction Locked (Please try the transaction again later)";
            break;

          case 'M':
            result = "Transaction Submitted (No response from acquirer)";
            break;

          case 'N':
            result = "Cardholder is not enrolled in Authentication scheme";
            break;

          case 'P':
            result = "Transaction has been received by the Payment Adaptor and is being processed";
            break;

          case 'R':
            result = "Transaction was not processed - Reached limit of retry attempts allowed";
            break;

          case 'S':
            result = "Duplicate SessionID";
            break;

          case 'T':
            result = "Address Verification Failed";
            break;

          case 'U':
            result = "Card Security Code Failed";
            break;

          case 'V':
            result = "Address Verification and Card Security Code Failed";
            break;

          case '?':
            result = "Transaction status is unknown";
            break;

          default:
            result = "Unable to be determined";
            break;
        }

        return result;
      } else {
        return "No Value Returned";
      }
    },
    beforeModel: async function (transition) {
      // console.log('query params');
      // console.log(transition.queryParams);
      let queryParam = transition.to.queryParams;
      queryParam['responseDescription'] = this.responseDescription(queryParam['vpc_TxnResponseCode']);
      queryParam['formatted_amount'] = parseFloat(queryParam['vpc_Amount']) / 100;
      this.set('queryParameters', queryParam); //verify hash first

      if (queryParam.vpc_SecureHash.length > 0 && queryParam.vpc_TxnResponseCode.length > 0) {
        let url_verify_hash = this.store.adapterFor('application').get('ecomWeb') + this.store.adapterFor('application').get('namespace') + '/baiduribanks/checkReturnHash';
        let verify_hash = await _axios.default.post(url_verify_hash, {
          paymentgateway: 2,
          paymentgatewaydetail: 4,
          parameters: queryParam
        }).catch(error => {
          console.log(error);
          alert(error);
        });

        if (verify_hash.data.hasValidHash === true) {
          //for testing, cancel will assume as paid payment
          //processing post payment
          let url_baiduri_charge = this.store.adapterFor('application').get('ecomWeb') + this.store.adapterFor('application').get('namespace') + '/baiduribanks/baiduriCharge';
          let baiduri_charge = await _axios.default.post(url_baiduri_charge, {
            formTransactionID: queryParam.vpc_OrderInfo,
            paymentRefNumber: queryParam.vpc_ReceiptNo,
            responseCode: queryParam.vpc_TxnResponseCode,
            responseMessage: queryParam.responseDescription,
            ipgNumber: queryParam.vpc_TransactionNo
          }).catch(error => {
            console.log(error);
            alert(error);
          });
        } // console.log('is hash valid?');
        // console.log(verify_hash.data.hasValidHash);


        this.set('hasValidHash', verify_hash.data.hasValidHash);
      } else {
        this.set('continueProcessing', false);
      }
    },

    model() {
      return this.store.query('order', {
        where: {
          user: this.get('authObject.data.user.id'),
          isdelete: false,
          // status: 'Done',
          order_number: this.get('queryParameters').vpc_OrderInfo
        }
      });
    },

    afterModel(resolvedModel) {
      console.log("afterModel"); //console.log(resolvedModel);
    },

    async setupController(controller, model) {
      model = model.objectAt(0);
      controller.set('model', model);
      controller.set('user', this.get('authObject.data.user'));
      controller.set('responseDescription', this.get('queryParameters.responseDescription'));
      let orderDetails = [];

      if (model.get('type') === 'Feature') {
        orderDetails = await this.store.query('featureexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (model.get('type') === 'Imagery') {
        orderDetails = await this.store.query('imageexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (model.get('type') === 'RSO') {
        orderDetails = await this.store.query('rsoexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (model.get('type') === 'Geodetic') {
        orderDetails = await this.store.query('geodeticexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      }

      let totalPrice = 0;
      orderDetails.forEach(record => {
        totalPrice += record.price;
        record.set("data_size", (record.data_size / 1048576).toFixed(5));
      });
      controller.set('orderDetails', orderDetails);
      this.set('allOrders', orderDetails);
      controller.set('totalPrice', totalPrice.toLocaleString());
      let host = this.store.adapterFor("application").get("ecomHost");
      let namespace = this.store.adapterFor("application").get("namespace");
      let endpoint = "/Formtransactions/?uniqueID=" + this.get('queryParameters.vpc_OrderInfo');
      let transaction = await (0, _axios.default)({
        method: "GET",
        url: host + namespace + endpoint
      }).then(response => {
        return response.data.formtransactions[0];
      }); //console.log('transaction');
      //console.log(transaction);

      controller.set('transaction', transaction);

      if (controller.get('orderType') === 'RSO' || controller.get('orderType') === 'Geodetic') {
        controller.set('show_dataarea', false);
      } else {
        controller.set('show_dataarea', false);
      }

      if ((model.get('type') === 'RSO' || model.get('type') === 'Geodetic') && transaction.status === 'SUCCESS') {
        controller.set('generateRSO', true);
      } else {
        controller.set('generateRSO', false);
      } //Mobile not allow to download PDF


      if ((0, _getCookie.default)('isMobile') === 'Yes') {
        controller.set('generateRSO', false);
      }
    },

    actions: {
      /**
       * Back to the Merchant Caller URL
       * @author Paskalouis Patressi
       */
      backToCaller: function () {
        window.location.href = this.controller.get('model.successURL');
      },

      gotoHome() {
        //cannot use transitionTo because it wont load the map
        //Go back to /momap if using mobile app
        if ((0, _getCookie.default)('isMobile') === 'Yes') {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/momap"));
        } else {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
        }
      },

      async downloadRSO(model) {
        let controller = this.get('controller');
        controller.set('start_loading', true);
        let pdf_name;
        controller.get('orderDetails').forEach((order, index) => {
          //console.log(order);
          if (index === 0) {
            pdf_name = order.pdf_name;
          }
        });
        let url_download = model.get('type') === 'RSO' ? '/rsosheets/downloadRSOSheet' : '/geodetics/downloadGeodeticSheet';
        let response = null;
        let url_featureexport = "".concat(this.store.adapterFor('application').get('serverHost')).concat(this.store.adapterFor('application').get('namespace')).concat(url_download);
        response = await _axios.default.post(url_featureexport, {
          order_number: model.get('order_number'),
          pdf_name
        }).catch(error => {
          console.log("An error occured while downloading ".concat(model.get('type'), " Sheet, ").concat(error.response.data));
          alert("An error occured while downloading ".concat(model.get('type'), " Sheet, ").concat(error.response.data));
          return null;
        });

        if (response.data) {
          let {
            base64,
            fileName,
            type
          } = response.data;
          let blob;

          try {
            blob = this.dataURItoBlob(base64);
          } catch (e) {
            // source: https://stackoverflow.com/a/21797381
            let binary_string = window.atob(base64);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }

            let arrayBuffer = bytes.buffer;
            blob = new Blob([arrayBuffer], {
              type
            });
          }

          let blobUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = blobUrl;
          a.download = fileName || blobUrl.split('/').pop();
          a.click();
          controller.set('start_loading', false);
        }
      }

    }
  });

  _exports.default = _default;
});