define("web-map/mixins/w3o-users-roles/set-session-user", ["exports", "web-map/mixins/w3o-users-roles/invalidate-session", "axios", "web-map/utils/get-cookie"], function (_exports, _invalidateSession, _axios, _getCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_invalidateSession.default, {
    authObject: Ember.inject.service('session'),

    setSessionUser() {
      return new Promise(async (resolve, reject) => {
        let user;
        let host = this.store.adapterFor("application").get("serverHost");
        let namespace = this.store.adapterFor("application").get("namespace"); //let new_user_url = `${host}${namespace}/users?email=${this.get('authObject.data.authenticated.user.email')}&status=Active`;

        let new_user_url = "".concat(host).concat(namespace, "/users/").concat(this.get('authObject.data.authenticated.user.id'));
        let response_new_user = await _axios.default.get(new_user_url).catch(error => {
          console.error("ERROR while fetch new_user");
          console.error(error);
        });

        if (response_new_user.status === 200) {
          user = response_new_user.data.users[0];
        }

        if (user && this.get('authObject.data.authenticated.user')) {
          const is_registereduser = async () => {
            let url_geo_userrole = "".concat(this.store.adapterFor('application').get('serverHost')).concat(this.store.adapterFor('application').get('namespace'), "/geoportalusergroups/checkUserRole");
            let geo_user = await _axios.default.post(url_geo_userrole, {
              userid: user.id
            }).catch(error => {
              console.log("An error occured while getting geoportal user role, ".concat(error));
              alert("An error occured while getting geoportal user role, ".concat(error));
            });

            if (geo_user.data.is_registered === false) {
              return {
                registered: false,
                isfoc: false,
                receivecashpayment: false,
                isInternal: false
              };
            } else {
              return {
                registered: true,
                isfoc: geo_user.data.is_foc,
                receivecashpayment: geo_user.data.is_cashpayment,
                isInternal: geo_user.data.is_internal
              };
            }
          };

          let userrole;

          if ((0, _getCookie.default)('isMobile') === 'Yes') {
            userrole = {
              registered: false,
              isfoc: false,
              receivecashpayment: false,
              isInternal: false
            };
          } else {
            userrole = await is_registereduser();
          }

          user.is_registered = userrole.registered;
          user.is_foc = userrole.isfoc;
          user.receive_cash_payment = userrole.receivecashpayment;
          user.is_internal = userrole.isInternal;
          this.get('authObject').set('data.user', user);

          if (Ember.isEmpty(this.get('authObject.data.locale'))) {
            this.set('authObject.data.locale', 'EN');
          }

          resolve(user);
        } else {
          this.invalidateSession();
          reject();
        }
      });
    }

  });

  _exports.default = _default;
});